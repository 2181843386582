import React from "react";
import { Container, Row, Col } from "react-bootstrap"


const Error = () => {
  return (
<Container>
    <Row>
      <Col lg={8} md={8} sm={12}>
        <h1>404</h1>

      </Col>
    </Row>
</Container>
)}

export default Error
